import type { AppRouteModule } from '/@/router/types';

import { LAYOUT } from '/@/router/constant';
import { t } from '/@/hooks/web/useI18n';

const dashboard: AppRouteModule = {
  path: '/home',
  name: 'Dashboard',
  component: LAYOUT,
  redirect: '/home/welcome',
  meta: {
    orderNo: 0,
    icon: 'ion:home',
    title: t('routes.dashboard.dashboard'),
    ignoreKeepAlive: true,
  },
  children: [
    {
      path: 'welcome',
      name: 'welcome',
      component: () => import('/@/views/dashboard/workbench/index.vue'),
      meta: {
        title: t('routes.dashboard.workbench'),
      },
    },
  ],
};

export default dashboard;
