import { useI18n } from '/@/hooks/web/useI18n';
const { t } = useI18n();

/**
 * @function getMenuI18n 获取国际化翻译
 * @param key 菜单 key
 * @return [] list
 */

export function getMenuI18n(key: string) {
  const menuStatus = new Map([
    ['abroad-guild-list', t('routes.abroadGuildChatManagement.AgencyList')], // 公会列表
    ['abroad-anchors-list', t('routes.abroadGuildChatManagement.HostList')], // 陪聊列表
    ['/abroad-guild-chat-management', t('routes.abroadGuildChatManagement.BDDashbord')], // 陪聊列表
  ]);
  return menuStatus.has(key) ? menuStatus.get(key) : false;
}
