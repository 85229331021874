import { notification } from 'ant-design-vue';

/**
 * 去除对象内多余的空值key
 * @author: 李博
 * @function removeNullKey 去除对象内多余的空值key
 * @param {object} object 需要操作的对象
 */
export const removeNullKey = (object = {}) => {
  const _obj = JSON.parse(JSON.stringify(object));
  for (const key in _obj) {
    if (['', null, undefined, []].indexOf(_obj[key]) !== -1) {
      delete _obj[key];
    }
  }
  return _obj;
};

/**
 * 下载文件
 * @author: 李博
 * @Date: 2020/8/20
 * @function downloadFile
 * @param file 文件数据
 * @param filename 文件名
 * @param type 类型
 */
export const downloadFile = (
  file,
  filename = '',
  type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
) => {
  const blob = new Blob([file], {
    type,
  });
  const downloadElement = document.createElement('a');
  const href = window.URL.createObjectURL(blob); // 创建下载的链接
  downloadElement.href = href;
  downloadElement.download = filename; // 下载后文件名
  document.body.appendChild(downloadElement);
  downloadElement.click(); // 点击下载
  document.body.removeChild(downloadElement); // 下载完成移除元素
  window.URL.revokeObjectURL(href); // 释放掉blob对象
  notification.success({
    message: '导出成功',
    duration: 2,
  });
};

// 字符串转换为数组
export const strFun = (str) => {
  if (!str) {
    return;
  }
  return str
    .replace(/[,，;；、|\n\s*\r]/g, '#')
    .replace(/#+/g, '#')
    .split('#')
    .filter((id) => id !== '');
};

/**
 * @Date: 2020/8/11
 * @function arrayToTree 数组转树
 * @param list 源数组
 */
export const arrayToTree = (list = []) => {
  const result = [];
  const target = {};
  list.forEach((menu: any) => {
    delete menu!.children;
  });
  list.forEach((menu) => {
    target[menu!.id] = menu;
  });
  list.forEach((menu) => {
    const parent = target[menu!.parentId];
    if (parent) {
      (parent.children || (parent.children = [])).push(menu);
    } else {
      result.push(menu);
    }
  });
  return result;
};

export const arrayToTree1 = (list = []) => {
  const result = [];
  const target = {};
  list.forEach((menu: any) => {
    delete menu!.children;
  });
  list.forEach((menu) => {
    target[menu!.id] = menu;
  });
  list.forEach((menu) => {
    const parent = target[menu!.parentId];
    Object.assign(menu, { meta: { title: menu.title } });
    if (parent) {
      (parent.children || (parent.children = [])).push(menu);
    } else {
      result.push(menu);
    }
  });
  return result;
};
