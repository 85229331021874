import { defHttp } from '/@/utils/http/axios';
import { getMenuListResultModel } from './model/menuModel';

enum Api {
  GetMenuList = '/services/admin/api/user/getUserAuthorities',
}

/**
 * @description: Get user menu based on id
 */

export const getMenuList = () => {
  return defHttp.get<getMenuListResultModel>({
    url: Api.GetMenuList,
    params: { userSource: 'OPERATION_MANAGE' },
  });
};
