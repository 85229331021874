export enum PageEnum {
  // basic login path
  BASE_LOGIN = '/login',
  // basic home path
  BASE_HOME = '/home',
  // error page path
  ERROR_PAGE = '/exception',
  // error log page path
  ERROR_LOG_PAGE = '/error-log/list',

  // 账号详情
  ADMIN_USER_DETAIL = '/user-setting/admin-user/detail/',
  // 账号新增
  ADMIN_USER_ADD = '/user-setting/admin-user/add',

  // 角色详情
  ROLE_DETAIL = '/user-setting/role/detail/',
  // 角色新增
  ROLE_ADD = '/user-setting/role/add',

  // 修改密码
  CHANGE_PASSWORD = '/change-password/index',

  // 公会列表-收款渠道历史
  GUILD_LIST_CHANNEL_HISTORY = '/guild-chat-management/guild-list/detail/',

  // 角色管理-添加角色
  ROLE_MANAGEMENT_ADD = '/user-permission/role-management/add',
}
