/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs from 'dayjs';

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';

export function formatToDateTime(date?: dayjs.ConfigType, format = DATE_TIME_FORMAT): string {
  return dayjs(date).format(format);
}

export function formatToDate(date?: dayjs.ConfigType, format = DATE_FORMAT): string {
  return dayjs(date).format(format);
}

export function getDateTimeString(utcTimestamp) {
  // 将UTC时间戳转换为本地时间
  const localTime = new Date(utcTimestamp);

  // 获取本地时间的年、月、日、时、分、秒
  const year = String(localTime.getFullYear()).slice(-2);
  const month = (localTime.getMonth() + 1).toString().padStart(2, '0');
  const day = localTime.getDate().toString().padStart(2, '0');
  const hours = localTime.getHours().toString().padStart(2, '0');
  const minutes = localTime.getMinutes().toString().padStart(2, '0');
  // const seconds = localTime.getSeconds().toString().padStart(2, '0');

  // 构建日期时间字符串
  const dateTimeString = `${year}/${month}/${day} ${hours}:${minutes}`;

  // 输出结果
  console.log(dateTimeString);
  return dateTimeString;
}

export const dateUtil = dayjs;
