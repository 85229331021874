import type { AppRouteModule } from '/@/router/types';

import { LAYOUT } from '/@/router/constant';
import { t } from '/@/hooks/web/useI18n';

const setup: AppRouteModule = {
  path: '/client-manage',
  name: 'AppClientManager',
  component: LAYOUT,
  meta: {
    orderNo: 3,
    icon: 'flat-color-icons:android-os',
    title: t('routes.clientManage.title'),
    ignoreKeepAlive: false,
  },
  children: [
    {
      path: 'app-version',
      name: 'AppVersion',
      component: () => import('/@/views/versionControl/index.vue'),
      meta: {
        title: t('routes.clientManage.appVersion'),
        ignoreAuth: true,
      },
    },
    {
      path: 'multi-package-review',
      name: 'MultiPackageReview',
      component: () => import('/@/views/MultiPackageReview/index.vue'),
      meta: {
        title: t('routes.clientManage.MultiPackageReview'),
        ignoreAuth: true,
      },
    },
  ],
};

export default setup;
